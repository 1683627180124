import React from "react"

import Navbar from "../components/navbar/Navbar"

import styles from "./layout.module.css"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"
library.add(faTimes, faChevronLeft, faChevronRight);

export default ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <Navbar />
      {children}
      <footer>
        <div>
          Third Times the Charm!
        </div>
        <div>
          7·3·21
        </div>
        <Link to="/rsvp">RSVP</Link>
      </footer>
    </div>
  )
}
