import React, { useState, useCallback } from "react"

import style from "./Navbar.module.css"
import { Link } from "gatsby"
import HamburgerMenuButton from "../hamburger-menu-button/HamburgerMenuButton"

export default () => {

  const [open, setOpen] = useState(false);

  const scrollAndSetOpen = useCallback((newOpenState: boolean) => {
    window.scrollTo(0, 0);
    setOpen(newOpenState);
  }, []);
  const close = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <div className={style.navbar}>
      <Link className={style.navbarTitle} to="/">
        ALEX & ALEXIS
      </Link>
      <div className={style.navbarItems}>
        <ul className={`${style.navbarList} ${open ? style.navbarList__open : ""}`}>
          <li
            className={`${style.navbarListItem} ${style.navbarListItemHomeLink}`}
            onClick={close}
          >
            <Link className={style.navbarListItemLink} to="/">
              Home
            </Link>
          </li>
          <li className={style.navbarListItem}>
            <Link className={style.navbarListItemLink} to="/our-story" onClick={close}>
              Our Story
          </Link>
          </li>
          <li className={style.navbarListItem}>
            <Link className={style.navbarListItemLink} to="/wedding" onClick={close}>
              Wedding
          </Link>
          </li>
          <li className={style.navbarListItem}>
            <Link className={style.navbarListItemLink} to="/registry" onClick={close}>
              Registry
          </Link>
          </li>
          <li className={style.navbarListItem}>
            <Link className={style.navbarListItemLink} to="/guide-to-stl" onClick={close}>
              Guide to STL
            </Link>
          </li>
          <li className={style.navbarListItem}>
            <Link className={style.navbarListItemLink} to="/gallery" onClick={close}>
              Gallery
          </Link>
          </li>
          <li className={style.navbarListItem}>
            <Link className={style.navbarListItemLink} to="/rsvp" onClick={close}>
              RSVP
          </Link>
          </li>
        </ul>

        <div className={`${style.hamburgerButtonContainer} ${open ? style.hamburgerButtonContainer__navbarList__open : ""}`}>
          <HamburgerMenuButton open={open} setOpen={scrollAndSetOpen} />
        </div>
      </div>
    </div>
  )
}
