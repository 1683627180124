import React, { useState } from "react"

import "hamburgers/dist/hamburgers.min.css";


interface HamburgerMenuButtonProps {
  open: boolean;
  setOpen: (val: boolean) => void
}

export default (props: HamburgerMenuButtonProps) => {
  return (
    <div
      className={`hamburger hamburger--spring ${props.open ? "is-active" : ""}`}
      onClick={() => {
        props.setOpen(!props.open);
      }}
    >
      <div className="hamburger-box">
        <div className="hamburger-inner" />
      </div>
    </div>
  );
}
