// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-gallery-index-tsx": () => import("./../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-guide-to-stl-index-tsx": () => import("./../src/pages/guide-to-stl/index.tsx" /* webpackChunkName: "component---src-pages-guide-to-stl-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-index-tsx": () => import("./../src/pages/our-story/index.tsx" /* webpackChunkName: "component---src-pages-our-story-index-tsx" */),
  "component---src-pages-registry-index-tsx": () => import("./../src/pages/registry/index.tsx" /* webpackChunkName: "component---src-pages-registry-index-tsx" */),
  "component---src-pages-rsvp-index-tsx": () => import("./../src/pages/rsvp/index.tsx" /* webpackChunkName: "component---src-pages-rsvp-index-tsx" */),
  "component---src-pages-wedding-index-tsx": () => import("./../src/pages/wedding/index.tsx" /* webpackChunkName: "component---src-pages-wedding-index-tsx" */)
}

